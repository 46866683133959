<template>
    <div class="pr-4">
        <v-subheader v-html="title" class="pl-0 font-medium"/>

        <v-switch
            class="mt-0"
            v-model="active"
            :disabled="saving || disabled"
            :loading="saving ? 'warning' : null"
            :persistent-hint="hint !== null && this.$vuetify.breakpoint.smAndUp"
            @click="changeFlagStatus($event)"
            :hint="hint"
        >
            <span slot="message">
                <div class="cursor-pointer" @click="hintVisible = ! hintVisible">
                    <template v-if="! hintVisible">
                        {{ $t('featureFlags.showHint') }}
                    </template>
                    <template v-else>
                        {{ hint }}<br>
                        {{ $t('featureFlags.hideHint') }}
                    </template>
                </div>
            </span>
            <template slot="label">
                <span :class="{'info--text': active && ! saving, 'warning--text': saving}">
                    {{ $t('featureFlags.active') }}
                </span>
            </template>
        </v-switch>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type:     Boolean,
            required: true,
        },

        title: {
            type:     String,
            required: true,
        },

        saving: {
            type:    Boolean,
            default: false,
        },

        disabled: {
            type:    Boolean,
            default: false,
        },

        description: {
            type:    String,
            default: null,
        },
    },

    watch: {
        value: function (newVal, oldVal) {
            this.active = newVal;
            this.original = newVal;
        },
    },

    mounted() {
        this.active = this.value;
        this.original = this.value;
    },

    data: () => ({
        active:      false,
        original:    false,
        hintVisible: false,
    }),

    methods: {
        changeFlagStatus($event) {
            $event.preventDefault();
            $event.stopPropagation();

            if (! this.saving) {
                const newValue = this.active !== this.original ? this.active : this.active !== true;

                this.$emit('flag-setting-changed', newValue);
            }
        },
    },

    computed: {
        hint() {
            if (this.description && this.description !== '') {
                return this.description;
            }

            return null;
        },
    },
};
</script>
