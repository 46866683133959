<template>
    <div class="pr-4">
        <v-subheader v-html="title" class="pl-0 font-medium"/>

        <v-text-field
            class="mt-0"
            v-model="input"
            :disabled="saving || disabled"
            :loading="saving ? 'warning' : null"
            :persistent-hint="hint !== null && this.$vuetify.breakpoint.smAndUp"
            @blur="changeFlagStatus($event)"
            :hint="hint"
        >

        </v-text-field>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },

        title: {
            type:     String,
            required: true,
        },

        saving: {
            type:    Boolean,
            default: false,
        },

        disabled: {
            type:    Boolean,
            default: false,
        },

        description: {
            type:    String,
            default: null,
        },
    },

    watch: {
        value: function (newVal, oldVal) {
            this.input    = newVal;
            this.original = newVal;
        },
    },

    mounted() {
        this.input    = this.value;
        this.original = this.value;
    },

    data: () => ({
        input:       null,
        original:    null,
        hintVisible: false,
    }),

    methods: {
        changeFlagStatus($event) {
            if (! this.saving) {
                const newValue = this.input !== this.original ? this.input : this.original;

                this.$emit('flag-setting-changed', newValue);
            }
        },
    },

    computed: {
        hint() {
            if (this.description && this.description !== '') {
                return this.description;
            }

            return null;
        },
    },
};
</script>
