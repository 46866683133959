<template>
    <div>
        <component
            :is="flagSettingComponent"
            v-if="flagSettingComponent"
            :value="flagSettingValue"
            :title="settingsTitle"
            :description="settingsDescription"
            :saving="savingSettings"
            :disabled="savingOtherFlags"
            :options="settingsOptions"
            v-on:flag-setting-changed="changeFlagSetting"
        />
        <template v-else>
            Die Einstellungen für diesen spezifischen Flag sind noch nicht integriert.
        </template>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

import SwitchSetting from './Switch';
import SelectSetting from './Select';
import TextSetting   from './Text';

export default {
    components: {
        SwitchSetting,
        SelectSetting,
        TextSetting,
    },

    props: {
        isInactiveSetting: {
            type:    Boolean,
            default: false,
        },

        savingOtherFlags: {
            default: false,
            type:    Boolean,
        },

        flag: {
            type: Object,
        },
    },

    data: () => ({
        active:         true,
        savingSettings: false,
    }),

    methods: {
        changeFlagSetting(value) {
            if (this.savingSettings) {
                return;
            }

            const settingsName = this.isInactiveSetting ? this.flag.settingsInactiveName : this.flag.settingsName;

            this.$store.commit('System/activateLoading');
            this.savingSettings    = true;
            let settings           = {};
            settings[settingsName] = value;
            this.$emit('feature-flag-settings:saving', true);

            this.$axios.post(
                this.route('user.feature-flag.set.setting', {area: this.flag.area, flag: this.flag.flag}),
                {
                    settings: settings,
                },
            ).then((result) => {
                this.$emit('flag-updated', result.data);
                this.$store.dispatch('FeatureFlags/updateFLag', result.data);

                this.$notifications.notify({
                    messageKey: 'featureFlags.notifications.success',
                    type:       'success',
                }, this);
            }).catch((response) => {
                this.$logger.error('Error saving flags', {response}, 'feature-flags');

                this.$notifications.notify({
                    messageKey: 'featureFlags.notifications.error',
                    type:       'error',
                }, this);
            }).finally(() => {
                this.savingSettings = false;
                this.$store.commit('System/deactivateLoading');
                this.$emit('feature-flag-settings:saving', false);
            });
        },
    },

    computed: {
        ...mapGetters('FeatureFlags', ['coreFlags', 'websiteFlags']),

        flagSettings() {
            let flagList;

            if (this.flag.area === 'website') {
                flagList = this.websiteFlags;
            } else {
                flagList = this.coreFlags;
            }

            if (typeof flagList[this.flag.flag] !== 'undefined'
                && typeof flagList[this.flag.flag].settings === 'object'
            ) {
                if (Array.isArray(flagList[this.flag.flag].settings) && flagList[this.flag.flag].settings.length > 0) {
                    return flagList[this.flag.flag].settings;
                } else if (Object.keys(flagList[this.flag.flag].settings).length > 0) {
                    return flagList[this.flag.flag].settings;
                }
            }

            return this.flag.settings || {};
        },

        flagSettingComponent() {
            const fieldVal = this.isInactiveSetting ? this.flag.settingsInactiveType : this.flag.settingsType;

            switch (fieldVal) {
                case 'switch':
                    return 'switch-setting';

                case 'select':
                    return 'select-setting';

                case 'text':
                    return 'text-setting';
            }

            return null;
        },

        flagSettingValue() {
            if (this.isInactiveSetting) {
                if (! this.flag.settingsInactiveName || this.flag.settingsInactiveName === '') {
                    this.$logger.debug(
                        'Unknown Feature Flag Settings Name (Inactive)',
                        this.flag,
                        'feature-flag-settings',
                    );
                    return false;
                }
            } else {
                if (! this.flag.settingsName || this.flag.settingsName === '') {
                    this.$logger.debug(
                        'Unknown Feature Flag Settings Name',
                        this.flag,
                        'feature-flag-settings',
                    );
                    return false;
                }
            }

            const settingsName = this.isInactiveSetting ? this.flag.settingsInactiveName : this.flag.settingsName;
            let settings       = this.flagSettings;

            if (typeof settings[settingsName] !== 'undefined') {
                return settings[settingsName];
            }

            if (this.isInactiveSetting) {
                if (typeof typeof this.flag.settingsInactiveDefault !== 'undefined') {
                    return this.flag.settingsInactiveDefault;
                }

                this.$logger.debug(
                    'No default setting for flag (inactive)',
                    this.flag,
                    'feature-flag-settings',
                );

                return false;
            }

            if (typeof typeof this.flag.settingsDefault !== 'undefined') {
                return this.flag.settingsDefault;
            }

            this.$logger.debug(
                'No default setting for flag',
                this.flag,
                'feature-flag-settings',
            );

            return false;
        },

        settingsTitle() {
            return this.isInactiveSetting ? this.flag.settingsInactiveTitle : this.flag.settingsTitle;
        },

        settingsDescription() {
            return this.isInactiveSetting ? this.flag.settingsInactiveDescription : this.flag.settingsDescription;
        },

        settingsOptions() {
            return this.isInactiveSetting ? (this.flag.settingsInactiveOptions || {}) : (this.flag.settingsOptions || {});
        },
    },
};
</script>
