<template>
    <div>
        <v-tabs v-if="useTabs && multipleGroups" show-arrows v-model="tab">
            <v-tab
                v-for="(flags, group) in flagGroups"
                :key="'tab-group-'+group"
                class="feature-flag-tab"
            >
                {{ group }}
            </v-tab>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(subGroups, group) in flagGroups"
                    :key="'tab-group-items-'+group"
                >
                    <v-card flat>
                        <v-card-text>
                            <flag-sub-groups :flag-sub-groups="subGroups" />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        <div v-else v-for="(subGroups, group) in flagGroups" class="mb-2">
            <v-subheader v-if="multipleGroups" class="feature-flag-group cursor-pointer" v-html="group" @click="toggleDisplay(group)"/>
            <v-divider v-if="multipleGroups" class="blue" :class="{
                'mb-4': $vuetify.breakpoint.smAndDown,
                'mb-2': $vuetify.breakpoint.mdAndUp,
                'darken-4': $vuetify.theme.dark,
                'accent-2': ! $vuetify.theme.dark,
            }"/>
            <v-slide-y-transition>
                <flag-sub-groups :flag-sub-groups="subGroups" />
            </v-slide-y-transition>
        </div>
    </div>
</template>

<style>
    .feature-flag-group {
        text-transform: capitalize;
    }

</style>

<script>
    import FlagSubGroups from './SubGroup';

    export default {
        components: {
            FlagSubGroups,
        },

        props: {
            flagGroups: {
                type: Object,
            },

            useTabs: {
                type: Boolean,
                default: true
            }
        },

        watch: {},

        mounted() {

        },

        beforeDestroy() {

        },

        data: () => ({
            tab: null,
            flagsVisibility: {}
        }),

        methods: {
            toggleDisplay(group) {
                if (typeof this.flagsVisibility[group] !== 'boolean') {
                    this.$set(this.flagsVisibility, group, false);
                } else {
                    this.flagsVisibility[group] = ! this.flagsVisibility[group];
                }
            }
        },

        computed: {
            multipleGroups() {
                return Object.keys(this.flagGroups).length > 1;
            }
        },
    };
</script>
