<template>
    <div class="pr-4">
        <v-subheader v-html="flag.title" class="pl-0 warning--text"/>

        <v-switch
            class="mt-0"
            v-model="active"
            :disabled="saving || savingSettings"
            :loading="saving ? 'warning' : null"
            :persistent-hint="hint !== null"
            @click="changeFlagStatus($event)"
            :hint="hint"
        >
            <span slot="message">
                <div class="cursor-pointer" @click="hintVisible = ! hintVisible">
                    <template v-if="! hintVisible">
                        {{ $t('featureFlags.showHint') }}
                    </template>
                    <template v-else>
                        {{ hint }}<br>
                        {{ $t('featureFlags.hideHint') }}
                    </template>
                </div>
            </span>
            <template slot="label">
                <span :class="{'info--text': active && ! saving, 'warning--text': saving}">{{ $t('featureFlags.active') }}</span>
            </template>
        </v-switch>

        <div class="mt-2" v-if="showSettings">
            <v-divider class="mt-3 mb-2"/>
            <template v-if="hasMultipleSettings">
                <handle-flag-setting
                    :key="flag.flag + '-' + (showInactiveSettings ? getFirstMultipleSetting.settingsInactiveName : getFirstMultipleSetting.settingsName)"
                    :flag="getFirstMultipleSetting"
                    :saving-other-flags="savingSettings"
                    v-on:feature-flag-settings:saving="savingSetting"
                    :is-inactive-setting="showInactiveSettings"
                />

                <template v-if="otherMultipleSettingsLength > 0">
                    <div
                        class="info--text mt-3 mb-1 cursor-pointer"
                        @click="showHideOtherSettings">
                        <v-icon key=1 color="info" v-if="! showOtherMultipleSettings">$plus</v-icon>
                        <v-icon key=2 color="info" v-else>$minus</v-icon>
                        <span class="mr-2"/>
                        {{ getOtherMultipleFlagsShowHideText }}
                    </div>

                    <v-expand-transition>
                        <div v-show="showOtherMultipleSettings">
                            <handle-flag-setting
                                v-for="setting in getOtherMultipleSettings"
                                :key="flag.flag + '-' + (showInactiveSettings ? setting.settingsInactiveName : setting.settingsName)"
                                :flag="setting"
                                :saving-other-flags="savingSettings"
                                v-on:feature-flag-settings:saving="savingSetting"
                                :is-inactive-setting="showInactiveSettings"
                            />
                        </div>
                    </v-expand-transition>
                </template>
            </template>
            <template v-else>
                <handle-flag-setting
                    :flag="flag"
                    :saving-other-flags="savingSettings"
                    v-on:feature-flag-settings:saving="savingSetting"
                    :is-inactive-setting="showInactiveSettings"
                />
            </template>
            <v-divider class="mt-3" v-if="false"/>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

import HandleFlagSetting from './Settings/HandleFlagSetting';

export default {
    components: {
        HandleFlagSetting,
    },

    props: {
        flag: {
            type: Object,
        },
    },

    watch: {
        flagActive: function (newVal, oldVal) {
            this.active   = newVal;
            this.original = newVal;
        },
    },

    mounted() {
        this.active   = this.flagActive;
        this.original = this.flagActive;
    },

    data: () => ({
        active:                            true,
        original:                          true,
        saving:                            false,
        savingSettings:                    false,
        otherMultipleFlagsVisible:         false,
        otherMultipleInactiveFlagsVisible: false,
        hintVisible:                       false,
    }),

    methods: {
        showHideOtherSettings() {
            if (this.showInactiveSettings) {
                this.otherMultipleFlagsVisible = ! this.otherMultipleFlagsVisible;
            } else {
                this.otherMultipleInactiveFlagsVisible = ! this.otherMultipleInactiveFlagsVisible;
            }
        },

        savingSetting(value) {
            this.savingSettings = value;
        },

        changeFlagStatus($event) {
            $event.preventDefault();
            $event.stopPropagation();

            if (this.saving) {
                return;
            }

            this.$store.commit('System/activateLoading');
            this.saving = true;

            const newValue     = this.active !== this.original ? this.active : this.active !== true;
            const route        = (newValue === false ? 'user.feature-flag.set.deactivate' : 'user.feature-flag.set.activate');
            const actualStatus = this.original;

            this.$axios.put(
                this.route(route, {area: this.flag.area, flag: this.flag.flag}),
            ).then((result) => {
                this.$emit('flag-updated', result.data);
                this.$store.dispatch('FeatureFlags/updateFLag', result.data);
                this.active   = result.data.active;
                this.original = result.data.active;

                this.$notifications.notify({
                    messageKey: 'featureFlags.notifications.success',
                    type:       'success',
                }, this);
            }).catch((response) => {
                this.active = actualStatus;
                this.$logger.error('Error saving flags', {response}, 'feature-flags');

                this.$notifications.notify({
                    messageKey: 'featureFlags.notifications.error',
                    type:       'error',
                }, this);
            }).finally(() => {
                this.saving = false;
                this.$store.commit('System/deactivateLoading');
            });
        },
    },

    computed: {
        ...mapGetters('FeatureFlags', ['coreFlags', 'websiteFlags']),

        flagActive() {
            let flagList;

            if (this.flag.area === 'website') {
                flagList = this.websiteFlags;
            } else {
                flagList = this.coreFlags;
            }

            if (typeof flagList[this.flag.flag] !== 'undefined') {
                return flagList[this.flag.flag].active;
            }

            return this.flag.active;
        },

        hint() {
            if (this.flag.description && this.flag.description !== '') {
                return this.flag.description;
            }

            return null;
        },

        showSettings() {
            return this.showActiveSettings || this.showInactiveSettings;
        },

        showActiveSettings() {
            return this.active && this.hasSettings;
        },

        showInactiveSettings() {
            return ! this.active && this.hasInactiveSettings;
        },

        hasMultipleSettings() {
            if (! this.active) {
                return this.flag.hasMultipleInactiveSettings;
            }

            return this.flag.hasMultipleSettings;
        },

        hasSettings() {
            if (this.flag.hasSettings === false) {
                return false;
            }

            if (this.flag.hasMultipleSettings !== true) {
                if (this.flag.settingsType
                    && this.flag.settingsType !== '') {
                    return true;
                }
            }

            return this.getMultipleSettingsEntries.length > 0;
        },

        hasInactiveSettings() {
            if (this.flag.hasInactiveSettings === false) {
                return false;
            }

            if (this.flag.hasMultipleInactiveSettings !== true) {
                if (this.flag.settingsInactiveType
                    && this.flag.settingsInactiveType !== '') {
                    return true;
                }
            }

            return this.getMultipleInactiveSettingsEntries.length > 0;
        },

        getMultipleSettingEntriesForState() {
            if (! this.active) {
                return this.getMultipleInactiveSettingsEntries;
            }

            return this.getMultipleSettingsEntries;
        },

        getMultipleSettingsEntries() {
            if (this.flag.hasSettings === false) {
                return [];
            }

            if (this.flag.hasMultipleSettings !== true
                || typeof this.flag.multipleSettings !== 'object'
                || ! Array.isArray(this.flag.multipleSettings)) {
                return [];
            }

            const finalList = [];

            this.flag.multipleSettings.forEach((setting) => {
                if (setting.settingsType
                    && setting.settingsType !== '') {
                    finalList.push({
                        ...{
                            flag: this.flag.flag,
                            area: this.flag.area,
                        },
                        ...setting,
                    });
                }

            });

            return finalList;
        },

        getMultipleInactiveSettingsEntries() {
            if (this.flag.hasInactiveSettings === false) {
                return [];
            }

            if (this.flag.hasMultipleInactiveSettings !== true
                || typeof this.flag.multipleInactiveSettings !== 'object'
                || ! Array.isArray(this.flag.multipleInactiveSettings)) {
                return [];
            }

            const finalList = [];

            this.flag.multipleInactiveSettings.forEach((setting) => {
                if (setting.settingsInactiveType
                    && setting.settingsInactiveType !== '') {
                    finalList.push({
                        ...{
                            flag: this.flag.flag,
                            area: this.flag.area,
                        },
                        ...setting,
                    });
                }

            });

            return finalList;
        },

        getFirstMultipleSetting() {
            if (this.getMultipleSettingEntriesForState < 0) {
                return {};
            }

            return this.getMultipleSettingEntriesForState[0];
        },

        getOtherMultipleSettings() {
            if (this.getMultipleSettingEntriesForState <= 1) {
                return [];
            }

            return this.getMultipleSettingEntriesForState.slice(1);
        },

        otherMultipleSettingsLength() {
            return this.getOtherMultipleSettings.length;
        },

        showOtherMultipleSettings() {
            if (this.showInactiveSettings) {
                return this.otherMultipleFlagsVisible;
            }

            return this.otherMultipleInactiveFlagsVisible;
        },

        getOtherMultipleFlagsShowHideText() {
            if (this.showOtherMultipleSettings) {
                return this.otherMultipleSettingsLength === 1
                    ? this.$t('featureFlags.oneOtherMultipleFlagsHide')
                    : this.$t('featureFlags.multipleOtherMultipleFlagsHide', {count: this.otherMultipleSettingsLength});
            }

            return this.otherMultipleSettingsLength === 1
                ? this.$t('featureFlags.oneOtherMultipleFlagsShow')
                : this.$t('featureFlags.multipleOtherMultipleFlagsShow', {count: this.otherMultipleSettingsLength});
        },
    },
};
</script>
