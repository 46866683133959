<template>
    <div class="transparent-tabs">
        <v-alert type="info" v-if="loading" outlined class="mb-3" dense icon="$spinner">{{ $t('featureFlags.loading') }}</v-alert>
        <v-tabs v-if="areaLength > 1" show-arrows :grow="$breakpoint.isMobile" v-model="tab" :vertical="$breakpoint.isDesktopOrLargeTablet">
            <v-tab
                v-for="(flagGroups, areaName) in allFlags"
                :key="'tab-'+areaName"
                class="feature-flag-tab"
            >
                {{ areaName }}
            </v-tab>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(flagGroups, areaName) in allFlags"
                    :key="'tab-items-'+areaName"
                >
                    <v-card flat>
                        <v-card-text>
                            <group :flag-groups="flagGroups" :use-tabs="useTabs"/>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        <group v-else-if="areaLength === 1" :use-tabs="useTabs" :flag-groups="allFlags[firstKey]"/>
        <div v-else>
            {{ $t('featureFlags.noFlags') }}
        </div>
    </div>
</template>

<style>
    .feature-flag-tab {
        text-transform: capitalize;
    }
</style>

<script>
    import Group from './Group';

    export default {
        components: {
            Group,
        },

        props: {},

        watch: {},

        mounted() {
            this.loadServerFeatureFlags();
            this.websiteFeatureFlags = this.$pluginManager.loadWebsiteFeatureFlags();
        },

        beforeDestroy() {
            this.abortActualRequests();
        },

        data: () => ({
            loading:             false,
            tab:                 null,
            websiteFeatureFlags: [],
            serverFlags:         [],
            axios:               null,
            useTabs:             true,
        }),

        methods: {
            abortActualRequests() {
                if (this.axios) {
                    this.axios.cancel();
                    this.axios = null;
                }
            },

            loadServerFeatureFlags() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.$store.commit('System/activateLoading');

                this.axios = this.$axiosToken.source();

                this.$axios.get(
                    this.route('feature-flags.load.for-user', {groupByArea: false, asArray: true}),
                    {
                        cancelToken: this.axios.token,
                    },
                ).then((result) => {
                    this.serverFlags = result.data;
                }).finally(() => {
                    this.loading = false;
                    this.axios   = null;
                    this.$store.commit('System/deactivateLoading');
                });
            },

            prepareFlags(flags, sourceFlagArray, areaToSet) {
                if (typeof flags !== 'object') {
                    flags = {};
                }

                for (let i = 0; i < sourceFlagArray.length; i++) {
                    let flag = sourceFlagArray[i];

                    if (typeof flag.forUser !== 'undefined' && flag.forUser === false) {
                        continue;
                    }

                    if (typeof flag.forUserVisibleVuex !== 'undefined' && Array.isArray(flag.forUserVisibleVuex)) {
                        const getter = this.generateVuexGetter(flag.forUserVisibleVuex);

                        if (getter && typeof this.$store.getters[getter] !== 'undefined' && this.$store.getters[getter] === false) {
                            continue;
                        }
                    }

                    if (typeof flags[flag.module] === 'undefined') {
                        flags[flag.module] = {};
                    }

                    if (typeof flags[flag.module][flag.group] === 'undefined') {
                        flags[flag.module][flag.group] = {
                            'Core':          [],
                            'Grundlegendes': [],
                            'Basis':         [],
                        };
                    }

                    if (typeof flags[flag.module][flag.group][flag.subGroup] === 'undefined') {
                        flags[flag.module][flag.group][flag.subGroup] = [];
                    }

                    if (areaToSet) {
                        flag.area = areaToSet;
                    }

                    flags[flag.module][flag.group][flag.subGroup].push(flag);
                }

                return flags;
            },
        },

        computed: {
            allFlags() {
                let flags = {};

                flags = this.prepareFlags(flags, this.websiteFeatureFlags, 'website');
                flags = this.prepareFlags(flags, this.serverFlags, 'core');

                this.$logger.debug(
                    'Feature Flags prepared',
                    flags,
                    'feature-flags',
                );

                return flags;
            },

            areaLength() {
                return Object.keys(this.allFlags).length;
            },

            firstKey() {
                const keys = Object.keys(this.allFlags);

                if (keys.length > 0) {
                    return keys[0];
                }

                return null;
            },
        },
    };
</script>
