<template>
    <div>
        <div v-for="(flags, group) in notEmptyGroups" class="mb-2">
            <template>
                <v-subheader
                    style="padding-left: 0 !important;"
                    class="feature-flag-sub-group info--text darken-2"
                    :class="{'cursor-pointer': multipleGroups}"
                    @click="toggleDisplay(group)">
                    <template v-if="multipleGroups">
                        <v-icon key=1 color="info" v-if="flagsVisibility[group] === false">$plus</v-icon>
                        <v-icon key=2 color="info" v-else>$minus</v-icon>
                    </template>
                    <span class="mr-2"/>
                    {{ group }}
                </v-subheader>
                <v-divider class="blue" :class="{
                'mb-4': $vuetify.breakpoint.smAndDown,
                'mb-2': $vuetify.breakpoint.mdAndUp,
                'darken-4': $vuetify.theme.dark,
                'accent-2': ! $vuetify.theme.dark,
            }"/>
            </template>

            <v-expand-transition>
                <v-row v-show="flagsVisibility[group] !== false">
                    <v-col
                        v-for="(flag, index) in flags"
                        :key="'specific-flag'+flag.flag"
                        cols="12"
                        sm="6"
                        lg="3"
                        md="4"
                    >
                        <flag-item class="flag-item" :flag="flag"></flag-item>
                    </v-col>
                </v-row>
            </v-expand-transition>
        </div>
    </div>
</template>

<style>
    .feature-flag-group {
        text-transform: capitalize;
    }

</style>

<script>
    import FlagItem from './Flag';

    export default {
        components: {
            FlagItem,
        },

        props: {
            flagSubGroups: {
                type: Object,
            },
        },

        watch: {},

        mounted() {

        },

        beforeDestroy() {

        },

        data: () => ({
            tab:             null,
            flagsVisibility: {},
        }),

        methods: {
            toggleDisplay(group) {
                if (! this.multipleGroups) {
                    return;
                }

                if (typeof this.flagsVisibility[group] !== 'boolean') {
                    this.$set(this.flagsVisibility, group, false);
                } else {
                    this.flagsVisibility[group] = ! this.flagsVisibility[group];
                }
            },
        },

        computed: {
            multipleGroups() {
                return Object.keys(this.notEmptyGroups).length > 1;
            },

            notEmptyGroups() {
                let groups = {};
                const keys = Object.keys(this.flagSubGroups);

                keys.forEach((key) => {
                    if (Object.values(this.flagSubGroups[key]).length > 0) {
                        groups[key] = this.flagSubGroups[key];
                    }
                });

                return groups;
            },
        },
    };
</script>
